import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
	selector: 'app-notfound-page',
	templateUrl: './notfound-page.component.html',
	styleUrls: ['./notfound-page.component.css'],
})
export class NotfoundPageComponent implements OnInit {
	constructor(
		private route: ActivatedRoute,
		private router: Router
	) {}

	ngOnInit(): void {
		const path = this.router.url.split('?')[0]; // Extract the path without query parameters
		if (path.includes('international-login')) {
			window.location.href = 'https://ex.shineexp.com/';
		} else if (path.includes('domestic-login')) {
			window.location.href = 'https://domestic.shineexp.com/auth/login';
		} else {
			console.error('Unexpected route, no redirection applied.');
		}
	}
}
