import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ServicesRoutingModule } from './services-routing.module';
import { ServicesPageComponent } from './services-page/services-page.component';
import { FeatureBlockComponent } from './feature-block/feature-block.component';


@NgModule({
	declarations: [ServicesPageComponent,FeatureBlockComponent],
	imports: [CommonModule, ServicesRoutingModule],
})
export class ServicesModule {}
