import { Component } from '@angular/core';

@Component({
	selector: 'app-notfound-page',
	templateUrl: './notfound-page.component.html',
	styleUrls: ['./notfound-page.component.css'],
})
export class NotfoundPageComponent {

	constructor() {}

	ngOnInit(): void {
	  // Redirect to the external URL when the component initializes
	  window.location.href = 'https://ex.shineexp.com/';
	}

}
